import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import axios from 'axios';
import icon from '../Dashboard/img/station.svg';
import env from "react-dotenv";

const MapContainer = ({ markerPosition, setMarkerPosition, setNewStation }) => {
  const [stations, setStations] = useState([]);
  const [address, setAddress] = useState(''); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const stationsResponse = await axios.get('https://back-end-ecotagger-214350645884.asia-southeast1.run.app/stations/display');
        const stationsData = stationsResponse.data.map(station => ({
          ...station,
          latitude: parseFloat(station.latitude),
          longitude: parseFloat(station.longitude),
          stationName: station.station_name
        }));
        setStations(stationsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleMapClick = useCallback(async (event) => {
    const position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setMarkerPosition(position); 
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const formattedAddress = results[0].formatted_address;
        console.log(formattedAddress); 
        setMarkerPosition({
          ...position,
          location: formattedAddress
        });
        setAddress(formattedAddress); 
        setNewStation(prevState => ({
          ...prevState,
          location: formattedAddress
        }));
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });
  }, [setMarkerPosition, setNewStation]); 

  const mapContainerStyle = {
    width: '100%',
    height: '500px',
  };

  const center = {
    lat: 10.6713,
    lng: 122.9511,
  };

  const googleMapsApiKey = process.env.REACT_APP_API_KEY;

  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={15}
        onClick={handleMapClick}
      >
        {stations.map((station) => (
          <Marker
            key={station.id}
            position={{ lat: station.latitude, lng: station.longitude }}
            icon={icon}
            label={station.stationName}
          />
        ))}

        {markerPosition && (
          <Marker
            position={markerPosition}
            icon={icon}
          />
        )}
      </GoogleMap>
      
      {markerPosition && (
        <div style={{ marginTop: '10px' }}>
          <p>Marker Coordinates:</p>
          <p>Latitude: {markerPosition.lat}</p>
          <p>Longitude: {markerPosition.lng}</p>
          <p>Address: {address}</p> {/* Display the fetched address */}
        </div>
      )}
    </LoadScript>
  );
};

export default MapContainer;