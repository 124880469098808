import './App.css';
import Login from './Components/Login/login';
import Signup from './Components/Signup/signup';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Analytics from './Components/Dashboard/analytics';
import Approval from './Components/Dashboard/approval';
import Users from './Components/Dashboard/users';
import Brands from './Components/Dashboard/brands';
import Collection from './Components/Dashboard/collection';
import Approved from './Components/Dashboard/approved';
import Declined from './Components/Dashboard/declined';
import Plastics from './Components/Dashboard/plastic';
import Stations from './Components/Dashboard/stations';
import Companies from './Components/Dashboard/plastic-comps';
import Products from './Components/Dashboard/products';
import UserAddBrandRequests from './Components/Dashboard/UserAddBrandRequests';
import UserAddProductRequests from './Components/Dashboard/UserAddProductRequests';
import Events from './Components/Dashboard/events';
import Api from './Components/DocsAPI/Api';
import MapContainer from './Components/Maps/Maps';
import Homepage from './Components/Home/homepage';
import PrivateRoute from './Components/auth/PrivateRoute';
import { useState } from 'react';
import PageNotFound from './Components/NotFound/NotFound';
import Types from './Components/Dashboard/types';
import SubTypes from './Components/Dashboard/subtypes';
import { ApiUrlProvider } from './Components/api/Api';



function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <ApiUrlProvider>
    <Router>
      <Routes>
        <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard/analytics" /> : <Login setIsAuthenticated={setIsAuthenticated} />} />
        
        <Route path="/docs" element={<Api />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/signup" element={<Signup />} />
        {/* <Route path="/dashboard/collection" element={<Collection />} /> */}
        
        {/* Private routes */}
        <Route path="/dashboard/subtypes" element={<PrivateRoute Component={SubTypes} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/dashboard/types" element={<PrivateRoute Component={Types} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/dashboard/analytics" element={<PrivateRoute Component={Analytics} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/dashboard/products" element={<PrivateRoute Component={Products} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/dashboard/approval" element={<PrivateRoute Component={Approval} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/dashboard/users" element={<PrivateRoute Component={Users} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/dashboard/stations" element={<PrivateRoute Component={Stations} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/dashboard/brands" element={<PrivateRoute Component={Brands} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/dashboard/companies" element={<PrivateRoute Component={Companies} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/dashboard/collection" element={<PrivateRoute Component={Collection} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        {/* <Route path="/dashboard/plastics" element={<PrivateRoute Component={Plastics} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} /> */}
        <Route path="/dashboard/approved" element={<PrivateRoute Component={Approved} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/dashboard/declined" element={<PrivateRoute Component={Declined} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/dashboard/UserAddBrandRequests" element={<PrivateRoute Component={UserAddBrandRequests} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/dashboard/UserAddProductRequests" element={<PrivateRoute Component={UserAddProductRequests} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/dashboard/collection/:id" element={<PrivateRoute Component={Events} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="maptest" element={<PrivateRoute Component={MapContainer} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
    </ApiUrlProvider>
  );
}

export default App;
