import React from 'react'
import Sidebar from './sidebar';
export default function Plastics() {
   const user = [
      { name: "1", color: "3712982", category: "Coca-Cola", price: "06-17-24" },
      { name: "2", color: "2131111", category: "PepsiCo", price: "06-23-24" },
      { name: "3", color: "2137172", category: "Nestle", price: "06-15-24" },
      { name: "4", color: "1123213", category: "Mndelez International", price: "05-02-24" },
      { name: "5", color: "32197321", category: "Unliever", price: "06-14-24" },
    ];
  return (
    <>
      <div class= "flex flex-row w-full h-screen w-full">
    <Sidebar />

<div class="bg-white p-4 w-4/5 flex ">
   <div class="bg-white p-4 w-full border-2 border-gray-200 border-none rounded-lg mt-14">
   <form class="max-w-md mx-auto m-5">   
    <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
    <div class="relative">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input type="search" id="default-search" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search Brand, Type..." required />
        <button type="submit" class="text-white absolute end-2.5 bottom-2.5 bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">Search</button>
    </div>
</form>
   <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">ID</th>
            <th scope="col" className="px-6 py-3">Company ID</th>
            <th scope="col" className="px-6 py-3">Brand Name</th>
            <th scope="col" className="px-6 py-3">Action</th>
          </tr>
        </thead>
        <tbody>
          {user.map((user, index) => (
            <tr key={index} className={`border-b ${index % 2 === 0 ? 'bg-green-50' : 'bg-white'}`}>
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                {user.name}
              </th>
              <td className="px-6 py-4">{user.color}</td>
              <td className="px-6 py-4">{user.category}</td>
              <td className="px-6 py-4">
                <a href="#" className="font-medium text-green-600 hover:underline">Edit</a>
                &nbsp;&nbsp;&nbsp;
                <a href="#" className="font-medium text-red-600 hover:underline">Delete</a>
                &nbsp;&nbsp;&nbsp;
                <a href="#" className="font-medium text-blue-600 hover:underline">View</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
   </div>
</div>
</div>
    </>
  )
}
