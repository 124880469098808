import React from 'react'
import logo from './img/logo.svg'
import backgroundImg from './img/background.svg';

export default function Signup() {
  return (
    <>
    <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center">
  <div className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1">
    <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
      <div>
        <img
          src={logo}
          className="w-32 mx-auto"
          alt="Logo"
        />
      </div>
      <div className="mt-12 flex flex-col items-center">
        <h1 className="text-2xl xl:text-3xl font-extrabold">
          Create Account
        </h1>
        <div className="w-full flex-1 mt-8">
          
         

          <div className="mx-auto max-w-xs">
            <input
              className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
              type="email"
              placeholder="Email"
            />
            <input
              className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
              type="password"
              placeholder="Username"
            />
            <input
              className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
              type="password"
              placeholder="Password"
            />
            <button
              className="mt-5 tracking-wide font-semibold bg-green-600 text-gray-100 w-full py-4 rounded-lg hover:bg-green-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
            >
              <svg className="w-6 h-6 -ml-2" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                <circle cx="8.5" cy="7" r="4" />
                <path d="M20 8v6M23 11h-6" />
              </svg>
              <span className="ml-3">
                Create Account  
              </span>
            </button>
            
          

            <div class="flex gap-2 pt-5">
      <p class="text-gray-600 text-sm">Already have an account?</p><a class="text-gray-600 text-sm underline"
        href="/">Login here</a>
    </div>

          </div>
        </div>
      </div>
    </div>
    <div className="flex-1 bg-green-100 text-center hidden lg:flex">
    <div
        className="m-12 xl:m-16   w-full bg-contain bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${backgroundImg})` }}
        >
      </div>
    </div>
  </div>
</div>
</>
  )
}
