import React, {useState, useEffect}from 'react'
import Sidebar from './sidebar';
import axios from 'axios';
import { useApiUrl } from '../api/Api';

export default function UserAddProductRequests() {
  const apiUrl = useApiUrl();
    const [productRequests, setProductRequests] = useState([])
    useEffect(()=>{
        axios
        .get(`https://back-end-ecotagger-214350645884.asia-southeast1.run.app/userRequest/getProductRequests`)
        .then((response) => {
            setProductRequests(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data", error);
        });
    })
    const approveReq = async(id)=>{
        try {
            const response = await axios.put(`https://back-end-ecotagger-214350645884.asia-southeast1.run.app/userRequest/approveReqProduct/${id}`);
            console.log(response.data);
          } catch (error) {
            console.error('Error submitting data:', error);
          }
    }
    const declineReq = async(id)=>{
        try {
            const response = await axios.put(`https://back-end-ecotagger-214350645884.asia-southeast1.run.app/userRequest/declineReqProduct/${id}`);
            console.log(response.data);
          } catch (error) {
            console.error('Error submitting data:', error);
          }
    }
  return (
    <>
   <div className="flex h-screen">
        <Sidebar />
        <div className="bg-white p-4 w-full ml-[20%] flex flex-col">
          <div className="bg-white p-4 w-full rounded-lg mt-14">
          <form className="max-w-md mx-auto m-5">
              <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">
                Search
              </label>
              <div className="relative">
                <input
                  type="search"
                  id="default-search"
                  className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                  placeholder="Search Location, Brand..."
                  required
                />
                <button
                  type="submit"
                  className="text-white absolute end-2.5 bottom-2.5 bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
                >
                  Search
                </button>
              </div>
            </form>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">User</th>
            <th scope="col" className="px-6 py-3">Brand Name</th>
            <th scope="col" className="px-6 py-3">Product Name</th>
            <th scope="col" className="px-6 py-3">Date Requested</th>
            <th scope="col" className="px-6 py-3">Status</th>
            <th scope="col" className="px-6 py-3">Action</th>
            
          </tr>
        </thead>
        <tbody>
          {productRequests.map((user, index) => (
            <tr key={index} className={`border-b dark:border-gray-700 ${index % 2 === 0 ? 'bg-green-50 dark:bg-gray-800' : 'bg-white dark:bg-gray-900'}`}>
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {user.username}
              </th>
              <td className="px-6 py-4">{user.brand_name}</td>
              <td className="px-6 py-4">{user.productName}</td>
              <td className="px-6 py-4">{user.dateRequested}</td>
              <td className="px-6 py-4">{user.reqStatus === 0 ? 'Pending' : user.reqStatus === 1 ? 'Approved' : 'Declined'}</td>
              <td className="px-6 py-4">
                <button onClick={() => approveReq(user.id)} disabled={user.reqStatus !== 0} href="#" className="font-medium text-green-600 dark:text-blue-500 hover:underline">Approve</button>
                &nbsp;&nbsp;&nbsp;
                <button onClick={() => declineReq(user.id)} disabled={user.reqStatus !== 0} href="#" className="font-medium text-red-600 dark:text-blue-500 hover:underline">Decline</button>
    
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
   </div>
</div>
</div>
    </>
  )
}
