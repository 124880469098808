import React from 'react'
import Sidebar from './sidebar';
export default function Declined() {
   const user = [
    { name: "1", color: "12321", category: "Declined", price: "06-17-24" },
    { name: "2", color: "32132", category: "Declined", price: "06-23-24" },
    { name: "3", color: "54544", category: "Declined", price: "06-15-24" },
    { name: "4", color: "43432", category: "Declined", price: "05-02-24" },
    { name: "5", color: "75565", category: "Declined", price: "06-14-24" },
    ];
  return (
    <>
      <div class= "flex flex-row w-full h-screen w-full">
    <Sidebar />

<div class="p-4 w-4/5 flex ">
   <div class="p-4 w-full border-2 border-gray-200 border-none rounded-lg dark:border-gray-700 mt-14">
   <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">ID</th>
            <th scope="col" className="px-6 py-3">Collection ID</th>
            <th scope="col" className="px-6 py-3">Status</th>
            <th scope="col" className="px-6 py-3">Action</th>
            
          </tr>
        </thead>
        <tbody>
          {user.map((user, index) => (
            <tr key={index} className={`border-b dark:border-gray-700 ${index % 2 === 0 ? 'bg-green-50 dark:bg-gray-800' : 'bg-white dark:bg-gray-900'}`}>
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {user.name}
              </th>
              <td className="px-6 py-4">{user.color}</td>
              <td className="px-6 py-4">{user.category}</td>
              <td className="px-6 py-4">
                <a href="#" className="font-medium text-green-600 dark:text-blue-500 hover:underline">Edit</a>
                &nbsp;&nbsp;&nbsp;
                <a href="#" className="font-medium text-red-600 dark:text-blue-500 hover:underline">Delete</a>
    
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
   </div>
</div>
</div>
    </>
  )
}
