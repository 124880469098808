import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ViewCollection = ({ onClose, user }) => {
  const { eventId } = useParams();
  const [collectionData, setCollectionData] = useState(null);

  useEffect(() => {
    const data = {
      eventId: 1,
      station: "Downtown Station",
      collectionName: "Weekly Trash Pickup",
      date: "2024-09-20",
      incharge: "GIZ USLS",
      eventImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDOmZHvcNaIFQPMMiMJ_qN-JOAZNNIBU9xuA&s"
    };
    
    setCollectionData(data);
  }, [eventId]);

  if (!collectionData) {
    return <p>Loading...</p>;
  }

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3 p-6">
        <div className="flex justify-between items-center border-b pb-2 mb-4">
          <h2 className="text-xl font-semibold text-gray-800">Event Information</h2>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-gray-600 focus:outline-none"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="space-y-4">
          <p><strong className="text-gray-600">Station:</strong> {collectionData.station}</p>
          <p><strong className="text-gray-600">Collection Name:</strong> {collectionData.collectionName}</p>
          <p><strong className="text-gray-600">Date:</strong> {collectionData.date}</p>
          <p><strong className="text-gray-600">Incharge:</strong> {collectionData.incharge}</p>
          <p><strong className="text-gray-600">Event Image:</strong></p>
          <div className="flex justify-center mt-2">
            <img 
              src={collectionData.eventImage} 
              alt="Event" 
              className="max-w-full max-h-60 object-contain"
            />
          </div>
        </div>

        <div className="mt-6 flex justify-end">
          <button 
            onClick={onClose} 
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewCollection;
