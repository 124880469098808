import React from 'react';
import Navbar from '../Home/Nav/Nav';

export default function Api() {
  return (
    <>
    <div className="sticky top-0 bg-white shadow-md z-10">
        <Navbar />
      </div>
    <div className="flex min-h-screen">

      {/* Sidebar */}
      <nav className="w-1/4 bg-gray-100 p-5 border-r border-gray-300">
        <h2 className="text-3xl font-semibold mb-6">EcoTagger API Documentation</h2>
        <ul className="space-y-4 text-lg">
          <li>
            <a href="#introduction" className="text-green-500 hover:text-green-700">
              Introduction
            </a>
          </li>
          <li>
            <a href="#authentication" className="text-green-500 hover:text-green-700">
              Authentication
            </a>
          </li>
          <li>
            <a href="#endpoints" className="text-green-500 hover:text-green-700">
              Endpoints
            </a>
          </li>
          <li>
            <a href="#errors" className="text-green-500 hover:text-green-700">
              Errors
            </a>
          </li>
        </ul>
      </nav>

      {/* Content */}
      <main className="flex-1 p-8">
        {/* Introduction Section */}
        <section id="introduction" className="mb-12">
          <h1 className="text-2xl font-semibold mb-4">Introduction</h1>
          <p className="text-gray-700">
            Welcome to the API documentation. This guide will walk you through the authentication process, available endpoints, and common error messages.
          </p>
        </section>

        {/* Authentication Section */}
        <section id="authentication" className="mb-12">
          <h2 className="text-xl font-semibold mb-4">Authentication</h2>
          <p className="text-gray-700">
            To authenticate, use the provided API key in the request headers:
          </p>
          <pre className="bg-gray-100 p-4 rounded-md mt-4">
            {`GET /api/v1/your-endpoint
Headers:
  Authorization: Bearer your-api-key`}
          </pre>
        </section>

        {/* Endpoints Section */}
        <section id="endpoints" className="mb-12">
          <h2 className="text-xl font-semibold mb-4">Endpoints</h2>
          <h3 className="text-lg font-semibold mb-2">GET /api/v1/resource</h3>
          <p className="text-gray-700">
            Retrieves a list of resources. Example request:
          </p>
          <pre className="bg-gray-100 p-4 rounded-md mt-4">
            {`GET /api/v1/resource`}
          </pre>

          <h3 className="text-lg font-semibold mb-2 mt-6">POST /api/v1/resource</h3>
          <p className="text-gray-700">
            Creates a new resource. Example request:
          </p>
          <pre className="bg-gray-100 p-4 rounded-md mt-4">
            {`POST /api/v1/resource
Body:
  {
    "name": "New Resource",
    "type": "example"
  }`}
          </pre>
        </section>

        {/* Errors Section */}
        <section id="errors">
          <h2 className="text-xl font-semibold mb-4">Errors</h2>
          <p className="text-gray-700">
            The API returns standard HTTP status codes to indicate success or failure of API requests:
          </p>
          <ul className="list-disc pl-5 mt-4 text-gray-700">
            <li><strong>200 OK:</strong> The request was successful.</li>
            <li><strong>400 Bad Request:</strong> The request was malformed.</li>
            <li><strong>401 Unauthorized:</strong> Authentication failed.</li>
            <li><strong>404 Not Found:</strong> The requested resource does not exist.</li>
            <li><strong>500 Internal Server Error:</strong> An error occurred on the server.</li>
          </ul>
        </section>
      </main>
    </div>
    </>
  );
}