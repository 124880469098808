import { useState, useEffect } from 'react';
import logo from '../../Dashboard/img/logo.svg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        // User is scrolling down
        setShowNavbar(false);
      } else {
        // User is scrolling up
        setShowNavbar(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={`bg-transparent fixed w-full z-30 top-0 transition-transform duration-300 ${
        showNavbar ? 'translate-y-0' : '-translate-y-full'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <div className="flex items-center">
            <img src={logo} className="h-11 me-3" alt="logo" />
            <a href="/" className="text-white text-2xl font-bold">EcoTagger</a>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <a href="/" className="text-white hover:text-green-500 px-3 py-2 rounded-md text-sm font-medium">Home</a>
              <a href="#about" className="text-white hover:text-green-500 px-3 py-2 rounded-md text-sm font-medium">Download</a>
              <a href="/docs" className="text-white hover:text-green-500 px-3 py-2 rounded-md text-sm font-medium">Docs</a>
              <a href="#contact" className="text-white hover:text-green-500 px-3 py-2 rounded-md text-sm font-medium">Contact</a>
            </div>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-white hover:text-gray-400 focus:outline-none"
            >
              <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a href="/home" className="text-white hover:text-gray-400 block px-3 py-2 rounded-md text-base font-medium">Home</a>
            <a href="#about" className="text-white hover:text-gray-400 block px-3 py-2 rounded-md text-base font-medium">Download</a>
            <a href="/docs" className="text-white hover:text-gray-400 block px-3 py-2 rounded-md text-base font-medium">Docs</a>
            <a href="#contact" className="text-white hover:text-gray-400 block px-3 py-2 rounded-md text-base font-medium">Contact</a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
