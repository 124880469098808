import React, { useState, useEffect } from 'react';
import Sidebar from './sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ViewCollection from './Modal/viewcollection';
import { useApiUrl } from '../api/Api';
import EditEventModal from './Modal/editevent';

export default function Collection() {
  const apiUrl = useApiUrl();
  const navigate = useNavigate();
  const [stations, setStations] = useState([]);
  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showeditModal, setShoweditModal] = useState(false);
  const [filter, setFilter] = useState('all');  
  const [searchQuery, setSearchQuery] = useState(''); 
  const [transactionData, setTransactionData] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [station, event] = await Promise.all([
          axios.get(`${apiUrl}/stations/display`),
          axios.get(`${apiUrl}/events/display`)
        ]);
        setStations(station.data);
        setEvents(event.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []); 

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    incharge: "",
    img: "",
    imgPreview: "",
    date: "",
    station_id: ""
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newData = new FormData();
    for (const key in formData) {
      if (formData[key] instanceof File) {
        newData.append('files', formData[key]);
      } else {
        newData.append(key, formData[key]);
      }
    }
    try {
      const response = await axios.post(`${apiUrl}/events/register`, newData);
      console.log(response.data);
      navigate(0);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log(formData);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevForm) => ({
          ...prevForm,
          [e.target.name]: file,
          [`${e.target.name}Preview`]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prevForm) => ({
        ...prevForm,
        [e.target.name]: "",
        [`${e.target.name}Preview`]: "",
      }));
    }
  };

  const getEventId = (id) => {
    console.log(id);
    navigate(`/dashboard/collection/${id}`);
  };

  const handleOpenModal = () => {
    setIsModalOpen1(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsModalOpen1(false);
    setShowModal(false);
  };


  const filteredEvents = events.filter(item => {
    if (filter === 'personal') return item.type === 1; 
    if (filter === 'events') return item.type === 0; 
    return true; // Show all
  });


  // router.get('/displaypersonal', EventsControl.getallPersonalEncodes);
  // router.get('/displayevents', EventsControl.getallEventEncodes);


  const handleSearch = (e) => {
    e.preventDefault(); 
    console.log('Search Query:', searchQuery);
    console.log('Selected Filter:', filter);
    
    const searchResults = events.filter((event) => {
      const matchesQuery = searchQuery
        ? event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          event.station_name.toLowerCase().includes(searchQuery.toLowerCase())
        : true;

      const matchesFilter = filter === 'all' || event.type === filter;

      return matchesQuery && matchesFilter;
    });

    setEvents(searchResults);
  };

  

  const viewEvent = async(id) =>{
    try {
      const response = await axios.get(`${apiUrl}/events/display/${id}`);
      setTransactionData(response.data[0])
      console.log(response.data);
      setShowModal(true);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  }

  

  const editEvent = async(id) =>{
    try {
      const response = await axios.get(`${apiUrl}/events/update/${id}`);
      setTransactionData(response.data[0])
      console.log(response.data);
      setShowModal(true);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  }

  const markDone = async(id) =>{
    try {
      const response = await axios.put(`${apiUrl}/events/done/${id}`);
      setTransactionData(response.data[0])
      console.log(response.data);
      setShowModal(true);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  }

  return (
    <>
      <div className="flex h-screen">
        <Sidebar />
        <div className="bg-white p-4 w-full ml-[20%] flex flex-col">
          <div className="bg-white p-4 w-full rounded-lg mt-14">
          <form className="max-w-lg mx-auto m-5" onSubmit={handleSearch}>
              <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">
                Search
              </label>
              <div className="relative">
                <div className="flex">
                  <input
                    type="search"
                    id="default-search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="block w-3/4 p-4 text-sm text-gray-900 border border-gray-300 rounded-l-lg bg-gray-50"
                    placeholder="Search Location, Brand..."
                    required
                  />
                  <select
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    className="block w-1/4 p-4 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-r-lg focus:ring-green-600 focus:border-green-600"
                  >
                    <option value="all">Show All</option>
                    <option value="personal">Show Personal</option>
                    <option value="events">Show Events</option>
                  </select>
                </div>
              </div>
            </form>

            <button 
              type="button" 
              onClick={handleOpenModal} 
              className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-green-600 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-green-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
            >
              Add Event
            </button>
            <button 
              type="button" 
              onClick={handleOpenModal} 
              className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-blue-600 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
            >
              Event Maps
            </button>
       

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th className="px-6 py-3">Station</th>
                    <th className="px-6 py-3">Event Name</th>
                    <th className="px-6 py-3">Description</th>
                    <th className="px-6 py-3">In charge</th>
                    <th className="px-6 py-3">Date</th>
                    <th className="px-6 py-3">Event Image</th>
                    <th className="px-6 py-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEvents.map((item, index) => (
                    <tr key={index} className={`border-b ${index % 2 === 0 ? 'bg-green-50' : 'bg-white'}`}>
                      <td className="px-6 py-4">{item.station_name}</td>
                      <td className="px-6 py-4">{item.title}</td>
                      <td className="px-6 py-4">{item.description}</td>
                      <td className="px-6 py-4">{item.incharge}</td>
                      <td className="px-6 py-4">{item.date}</td>
                      <td className="px-6 py-4">
                        <img className='images' src={item.img ? `${apiUrl}/${item.img.replace(/\\/g, "/")}` : null}
                          alt={item.img ? `https://back-end-ecotagger-214350645884.asia-southeast1.run.app/${item.img.replace(/\\/g, "/")}` : item.img} />
                      </td>
                      <td className="px-6 py-4">
                        <a href="#" onClick={() => viewEvent(item.id)} className="font-medium text-green-600 hover:underline">Details</a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#" className="font-medium text-blue-600 hover:underline" onClick={() => getEventId(item.id)}>Transactions</a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#" className="font-medium text-blue-600 hover:underline" 
                         onClick={() => {
                          setSelectedCollection(item); 
                          setIsModalOpen(true);
                        }}>Edit</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen1 && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-1/4 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-black text-xl font-semibold mb-4">Add New Event</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <select
                  name="station_id"
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                >
                  <option value="">Select a Station</option>
                  {stations.map((station, index) => (
                    <option key={index} value={station.id}>{station.station_name}</option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Event Title</label>
                <input
                  type="text"
                  name="title"
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Description</label>
                <textarea
                  name="description"
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">In Charge</label>
                <input
                  type="text"
                  name="incharge"
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Event Image</label>
                <input
                  type="file"
                  name="img"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Date</label>
                <input
                  type="date"
                  name="date"
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="flex justify-end">
                <button type="button" onClick={handleCloseModal} className="mr-2 px-4 py-2 bg-red-600 text-white rounded-md">Cancel</button>
                <button type="submit" className="px-4 py-2 bg-green-600 text-white rounded-md">Add Event</button>
              </div>
            </form>
          </div>
        </div>
      )}

{showModal && transactionData && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3 relative">
      {/* Close Button */}
      <button
        onClick={handleCloseModal}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
      >
        ✕
      </button>
      
      <h2 className="text-black text-xl font-semibold mb-4">Event Details</h2>
      <div>
        <p><strong>Station Name:</strong> {transactionData.title}</p>
        <p><strong>Description:</strong> {transactionData.description}</p>
        <p><strong>In Charge:</strong> {transactionData.title}</p>
        <p><strong>Station_id:</strong> {transactionData.description}</p>
        <p><strong>Date:</strong> {transactionData.date}</p>
        <p><strong>Event Image:</strong></p>
        <img className='images' src={transactionData.img ? `${apiUrl}/${transactionData.img.replace(/\\/g, "/")}` : null}
        alt={transactionData.img ? `https://back-end-ecotagger-214350645884.asia-southeast1.run.app/${transactionData.img.replace(/\\/g, "/")}` : transactionData.img} />
      </div>

      <div className="flex justify-end mt-4 space-x-2">
        <button
          onClick={() => editEvent(transactionData.id)}
          className="px-4 py-2 bg-blue-600 text-white rounded-md"
        >
          Edit
        </button>
        <button
          onClick={() => markDone(transactionData.id)}
          className="px-4 py-2 bg-green-600 text-white rounded-md"
        >
          Mark as Done
        </button>
      </div>
    </div>
  </div>
)}
          {isModalOpen && (

                <EditEventModal
                  item={selectedCollection}
                  onClose={() => setIsModalOpen(false)}
                />

          )}
    </>
  );
}
