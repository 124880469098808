import React, { useState, useEffect } from 'react';
import Sidebar from './sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ViewProducts from './Modal/viewproducts';
import { useApiUrl } from '../api/Api';

export default function Products() {
  const apiUrl = useApiUrl();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [company, setCompany] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const handleView = () => {
    setShowModal(true);
  };
  const [newProduct, setNewProduct] = useState({ brand_id: '', product_name: '',img_path: "",
    imgPreview: ""});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [brandResponse,productResponse,companyResponse] = await Promise.all([
          axios.get(`${apiUrl}/brands/display`),
          axios.get(`${apiUrl}/products/display`),
          axios.get(`${apiUrl}/company/display`)
        ])
        setBrands(brandResponse.data);
        setProducts(productResponse.data);
        setCompany(companyResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = (key) => {
    setSortBy(key);
  };
  const [specificBrands, setspecificBrands] = useState([])
  const getSpecificBrands = async(id)=>{
    console.log(id)
    try {
      const [brandResponse] = await Promise.all([
        axios.get(`${apiUrl}/brands/display/${id}`)
      ])
      setspecificBrands(brandResponse.data);
      console.log(brandResponse.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleSubmit = async(e) =>{
    e.preventDefault();
    const formData = new FormData();
    for (const key in newProduct) {
      if (newProduct[key] instanceof File) {
        formData.append('files', newProduct[key]);
      } else {
        formData.append(key, newProduct[key]);
      }
    }
    try {
      const response = await axios.post(`${apiUrl}/products/register`,formData,);
      console.log(response.data);
      navigate(0);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  }
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setNewProduct({
      company_id: '', brand_name: '',img_path: "",
    imgPreview: ""
    })
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
    
    console.log(newProduct)
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewProduct((prevForm) => ({
          ...prevForm,
          [e.target.name]: file,
          [`${e.target.name}Preview`]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setNewProduct((prevForm) => ({
        ...prevForm,
        [e.target.name]: "",
        [`${e.target.name}Preview`]: "",
      }));
    }
  };

  const deleteProduct = async(id) =>{
    try {
      const response = await axios.put(`${apiUrl}/products/delete/${id}`);
      console.log(response.data);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  }
  return (
    <>
      <div className="flex h-screen">
        <Sidebar />
        <div className="bg-white p-4 w-full ml-[20%] flex flex-col">
          <div className="bg-white p-4 w-full rounded-lg mt-14">
            <form className="max-w-md mx-auto m-5">
              <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                  </svg>
                </div>
                <input 
                  type="search" 
                  id="default-search" 
                  className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" 
                  placeholder="Search Name, Role..." 
                  required 
                  onChange={handleSearchChange}
                />
                <button 
                  type="submit" 
                  className="text-white absolute end-2.5 bottom-2.5 bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
                >
                  Search
                </button>
              </div>
            </form>
            <button 
              type="button" 
              onClick={handleOpenModal} 
              className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-green-600 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-green-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
            >
              Add Products
            </button>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">Brand Name</th>
                   
                    <th scope="col" className="px-6 py-3">
                      <div className="flex items-center">
                        Product Name
                        <a href="#"><svg className="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                        </svg></a>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3">Product Image</th>
                    <th scope="col" className="px-6 py-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {products.slice().reverse().map((product, index) => (
                    <tr key={index} className={`border-b ${index % 2 === 0 ? 'bg-green-50' : 'bg-white'}`}>
                      <td className="px-6 py-4">{product.brand_name}</td>
                      <td className="px-6 py-4">{product.product_name}</td>
                      <td className="px-6 py-4">
                      <img className='images' src={ product.img_path? `https://back-end-ecotagger-214350645884.asia-southeast1.run.app/${product.img_path.replace(/\\/g,"/")}` : null }
                      alt={product.img_path? `https://back-end-ecotagger-214350645884.asia-southeast1.run.app/${product.img_path.replace(/\\/g,"/")}`: product.img_path}/>
                      </td>
                      <td className="px-6 py-4">
                        <a href="#" className="font-medium text-green-600 hover:underline">Edit</a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#"  onClick={() => deleteProduct(product.id)} className="font-medium text-red-600 hover:underline">Delete</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-1/4 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-black text-xl font-semibold mb-4">Add New Product</h2>
            <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Company Name</label>
                <select
                  name="company_id"
                  onChange={(e) => getSpecificBrands(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                >
                  <option value="">Select a company</option>
                  {company.map((company, index) => (
                    <option key={index} value={company.id}>{company.company_name}</option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Brand Name</label>
                <select
                  name="brand_id"
                  value={newProduct.brand_id}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                >
                  <option value="">Select a company</option>
                  {specificBrands.map((brand, index) => (
                    <option key={index} value={brand.brandID}>{brand.brand_name}</option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Product Name</label>
                <input
                  type="text"
                  name="product_name"
                  value={newProduct.product_name}
                  onChange={handleInputChange}
                  className="bg-white mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="mb-4" >
            <label>
              <div className="textimg">Choose Product Image:</div>
              <div className="imagepreview">
                {newProduct.imgPreview && (
                  <img
                    src={newProduct.imgPreview}
                    alt="img"
                    style={{ width: "200px", height: "200px" }}
                  />
                )}
              </div>
              <div className="imageinput">
                <input
                  type="file"
                  name="img"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
            </label>
          </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="bg-red-600 text-white px-4 py-2 rounded-md mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-600 text-white px-4 py-2 rounded-md"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showModal && (
            <ViewProducts
              show={showModal}
              onClose={() => setShowModal(false)}

            >
              <div>
                <h2>Product Information</h2>
                <p><strong className="text-gray-600">Company Name:</strong></p>
                <p><strong className="text-gray-600">Brand Name:</strong></p>
                <p><strong className="text-gray-600">Product Name:</strong></p>
                <p><strong className="text-gray-600">Status:</strong></p>
                <a href='' target="_blank" rel="noopener noreferrer">
                  View Document
                </a>
                <p><strong>Status:</strong> </p>
              </div>
            </ViewProducts>
          )}
    </>
  );
}