import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useApiUrl } from '../../api/Api';

function EditEvent({ item, onClose }) {
    const apiUrl = useApiUrl();
    const [stations, setStations] = useState([]);
    const [title, setTitle] = useState(item.title);
    const [description, setDescription] = useState(item.description);
    const [incharge, setIncharge] = useState(item.incharge);
    const [img, setImg] = useState(item.img);
    const [date, setDate] = useState(item.date);
    const [station_id, setStation_id] = useState(item.station_id ? String(item.station_id) : '');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: station } = await axios.get(`${apiUrl}/stations/display`);
                setStations(station);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [apiUrl]);

    useEffect(() => {
        setTitle(item.title);
        setDescription(item.description);
        setIncharge(item.incharge);
        setImg(item.img);
        setDate(item.date);
        setStation_id(item.station_id ? String(item.station_id) : '');
    }, [item]);

    const handleEdit = () => {
        const updatedEvent = { title, description, incharge, img, date, station_id };

        axios.put(`${apiUrl}/events/update/${item.id}`, updatedEvent)
            .then(response => {
                console.log('Event updated:', response.data);
            })
            .catch(error => {
                console.error('Error updating event:', error);
            });
    };

    return (
        <div>
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="w-1/4 bg-white p-6 rounded-lg shadow-lg relative">
                    <button
                        onClick={onClose}
                        className="absolute top-3 right-3 text-gray-600 hover:text-gray-800 font-bold text-xl"
                    >
                        &times;
                    </button>
                    <h2 className="text-black text-xl font-semibold mb-4">Edit Event "{title}"</h2>
                    <div className="mb-4">
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Title"
                            className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Description"
                            className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="text"
                            value={incharge}
                            onChange={(e) => setIncharge(e.target.value)}
                            placeholder="In Charge"
                            className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="file"
                            onChange={(e) => setImg(e.target.files[0])}
                            placeholder="Image"
                            className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="text"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            placeholder="Date"
                            className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <select
                            name="station_id"
                            value={station_id}
                            onChange={(e) => setStation_id(e.target.value)}
                            className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        >
                            <option value="" disabled>Select a Station</option>
                            {stations.map((station) => (
                                <option key={station.id} value={String(station.id)}>
                                    {station.station_name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <button onClick={handleEdit} className="m-2 bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded">Save Changes</button>
                    <button onClick={onClose} className="bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded">Close</button>
                </div>
            </div>
        </div>
    );
}

export default EditEvent;
