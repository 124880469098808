import React, { createContext, useContext } from "react";

const ApiUrlContext = createContext();

//  192.168.0.209 GIZ OFFICE
//  http://192.168.1.12 Jal

export const ApiUrlProvider = ({ children }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
  return (
    <ApiUrlContext.Provider value={apiUrl}>
      {children}
    </ApiUrlContext.Provider>
  );
};

export const useApiUrl = () => {
  return useContext(ApiUrlContext);
};