import React, { useState, useEffect } from 'react';
import Sidebar from './sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ViewCompany from './Modal/viewcompany';
import { useApiUrl } from '../api/Api';


export default function Companies() {
  const apiUrl = useApiUrl();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [company, setCompany] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [companyResponse] = await Promise.all([
          axios.get('https://back-end-ecotagger-214350645884.asia-southeast1.run.app/company/display')
        ])
        setCompany(companyResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCompany, setNewCompany] = useState({ company_name: '', company_address: '', company_number: '',img_path: "",
    imgPreview: "", company_email: ''});

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setNewCompany({
      company_id: '', brand_name: '',img_path: "",
    imgPreview: ""
    })
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCompany({ ...newCompany, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in newCompany) {
      if (newCompany[key] instanceof File) {
        formData.append('files', newCompany[key]);
      } else {
        formData.append(key, newCompany[key]);
      }
    }
    try {
      const response = await axios.post('https://back-end-ecotagger-214350645884.asia-southeast1.run.app/company/register', formData);
      console.log(response.data);
      console.log(formData)
      handleCloseModal();
      navigate(0);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewCompany((prevForm) => ({
          ...prevForm,
          [e.target.name]: file,
          [`${e.target.name}Preview`]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setNewCompany((prevForm) => ({
        ...prevForm,
        [e.target.name]: "",
        [`${e.target.name}Preview`]: "",
      }));
    }
  };

  const deleteComp = async(id) =>{
    try {
      const response = await axios.put(`https://back-end-ecotagger-214350645884.asia-southeast1.run.app/company/delete/${id}`);
      console.log(response.data);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  }
  return (
    <>
       <div className="flex h-screen">
      <Sidebar />
      <div className="bg-white p-4 w-full ml-[20%] flex flex-col">
          <div className="bg-white p-4 w-full rounded-lg mt-14">
            <form className="max-w-md mx-auto m-5">
              <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                  </svg>
                </div>
                <input type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search Brand, Type..." required />
                <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">Search</button>
              </div>
            </form>
            <button type="button" onClick={handleOpenModal} className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-green-600 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-green-700 focus:z-10 focus:ring-4 focus:ring-gray-100 ">Add Company</button>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">Company Name</th>
                    <th scope="col" className="px-6 py-3">Address</th>
                    <th scope="col" className="px-6 py-3">Company Number</th>
                    <th scope="col" className="px-6 py-3">Company Email</th>
                    <th scope="col" className="px-6 py-3">Company Image</th>
                    <th scope="col" className="px-6 py-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {company.slice().reverse().map((company, index) => (
                    <tr key={index} className={`border-b ${index % 2 === 0 ? 'bg-green-50' : 'bg-white'}`}>
                      <td className="px-6 py-4">{company.company_name}</td>
                      <td className="px-6 py-4">{company.company_address}</td>
                      <td className="px-6 py-4">{company.company_number}</td>
                      <td className="px-6 py-4">{company.company_email}</td>
                      <td className="px-6 py-4">
                      <img className='images' src={ company.img_path? `https://back-end-ecotagger-214350645884.asia-southeast1.run.app/${company.img_path.replace(/\\/g,"/")}` : null }
                      alt={company.img_path? `http://localhost:4000/${company.img_path.replace(/\\/g,"/")}`: company.img_path}/>
                      </td>
                      <td className="px-6 py-4">
                        <a href="#" className="font-medium text-green-600 hover:underline">Edit</a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#" onClick={() => deleteComp(company.id)} className="font-medium text-red-600 hover:underline">Delete</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-1/4 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4 text-black">Add New Company</h2>
            <form onSubmit={handleSubmit}>
             
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Company Name</label>
                <input
                  type="text"
                  name="company_name"
                  value={newCompany.company_name}
                  onChange={handleInputChange}
                  className="bg-white mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Address</label>
                <input
                  type="text"
                  name="company_address"
                  value={newCompany.company_address}
                  onChange={handleInputChange}
                  className="bg-white mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Company Number</label>
                <input
                  type="text"
                  name="company_number"
                  value={newCompany.company_number}
                  onChange={handleInputChange}
                  className="bg-white mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Company Email</label>
                <input
                  type="email"
                  name="company_email"
                  value={newCompany.company_email}
                  onChange={handleInputChange}
                  className="bg-white mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="mb-4" >
            <label>
              <div className="textimg">Choose Company Image:</div>
              <div className="imagepreview">
                {newCompany.imgPreview && (
                  <img
                    src={newCompany.imgPreview}
                    alt="img"
                    style={{ width: "200px", height: "200px" }}
                  />
                )}
              </div>
              <div className="imageinput">
                <input
                  type="file"
                  name="img"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
            </label>
          </div>
              <div className="flex justify-end">
                <button type="button" onClick={handleCloseModal} className="py-2 px-4 bg-red-500 text-white rounded-md mr-2">Cancel</button>
                <button type="submit" className="py-2 px-4 bg-green-600 text-white rounded-md">Add</button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showModal && (
            <ViewCompany
              show={showModal}
              onClose={() => setShowModal(false)}

            >
              <div>
                <h2>Company Information</h2>
                <p><strong className="text-gray-600">Company Name:</strong></p>
                <p><strong className="text-gray-600">Address:</strong></p>
                <p><strong className="text-gray-600">Company Number:</strong></p>
                <p><strong className="text-gray-600">Company Email:</strong></p>
                <p><strong className="text-gray-600">Status:</strong></p>
                <a href='' target="_blank" rel="noopener noreferrer">
                  View Document
                </a>
                <p><strong>Status:</strong> </p>
              </div>
            </ViewCompany>
          )}
    </>
  );
}