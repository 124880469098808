import React, { useEffect, useState } from 'react';
import backgroundImg from './img/splash.svg';
import logo from './img/logo.svg';
import { useNavigate } from 'react-router-dom';

export default function Login({ setIsAuthenticated }) {
  const [scale, setScale] = useState('scale-100');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  
  const navigate = useNavigate();

  const fixedUsername = 'gizadmin'; // Your fixed username
  const fixedPassword = 'gizde123'; // Your fixed password

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === fixedUsername && password === fixedPassword) {
      setIsAuthenticated(true);
      navigate('/dashboard/analytics'); // Redirect to the protected route
    } else {
      alert('Invalid username or password');
    }
  };

  useEffect(() => {
    const detectZoom = () => {
      const zoomLevel = Math.round(window.devicePixelRatio * 100);
      if (zoomLevel === 150 || zoomLevel === 175) {
        setScale('scale-100 overflow-hidden');
      } else {
        setScale('scale-100');
      }
    };

    // Detect zoom level on load
    detectZoom();

    // Add event listener for zoom level changes
    window.addEventListener('resize', detectZoom);

    return () => {
      window.removeEventListener('resize', detectZoom);
    };
  }, []);

  return (
    <>
      <div className={`min-h-screen bg-gray-100 text-gray-900 flex justify-center ${scale}`}>
        <div className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1">
          <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
            <div>
              <img src={logo} className="w-32 mx-auto" alt="Logo" />
            </div>
            <div className="mt-12 flex flex-col items-center">
              <h1 className="text-2xl xl:text-3xl font-extrabold">Login</h1>
              <div className="w-full flex-1 mt-8">
                <div className="mx-auto max-w-xs">
                  <form onSubmit={handleLogin}>
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                      type="text"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <button
                      type="submit"
                      className="mt-5 tracking-wide font-semibold bg-green-600 text-gray-100 w-full py-4 rounded-lg hover:bg-green-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                    >
                      Login
                    </button>
                  </form>

                  <div className="flex gap-2 pt-5">
                    <p className="text-gray-600 text-sm">Don't have an account?</p>
                    <a className="text-gray-600 text-sm underline" href="/signup">Register here</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 bg-green-100 text-center hidden lg:flex">
            <div
              className="m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${backgroundImg})` }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
