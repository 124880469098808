import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useApiUrl } from '../../api/Api';

function EditBrand({ brand, onClose }) {  
    const apiUrl = useApiUrl();
    const [stations, setStations] = useState([]);
    const [name, setName] = useState(brand.brand_name);
    const [company, setCompany] = useState([]);
    const [company_id, setComp_id] = useState(brand.company_id);
    const [img, setImg] = useState(brand.img_path);
    // const [description, setDescription] = useState(item.description);
    // const [incharge, setIncharge] = useState(item.incharge);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          setImg(URL.createObjectURL(file)); // Temporarily display the selected image
        }
      };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: companys } = await axios.get(`${apiUrl}/company/display`);
                setStations(companys);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [apiUrl]);


    useEffect(() => {
        setName(brand.product_name);
        setComp_id(brand.company_id);
        setImg(brand.img_path);

    }, [brand]);

    const handleEdit = () => {
        const updatedEvent = { company_id, name, img };


        axios.put(`${apiUrl}/brands/update/${brand.id}`, updatedEvent)
            .then(response => {
                console.log('Event updated:', response.data);
            })
            .catch(error => {
                console.error('Error updating event:', error);
            });
    };

    return (
        <div>
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="w-1/4 bg-white p-6 rounded-lg shadow-lg relative">
                    <button
                        onClick={onClose}  
                        className="absolute top-3 right-3 text-gray-600 hover:text-gray-800 font-bold text-xl"
                    >
                        &times;
                    </button>
                    <h2 className="text-black text-xl font-semibold mb-4">Edit Event "{brand.brand_name}"</h2>
                    <div className="mb-4">
                        <select
                            name="company_id"
                            value={company_id}
                            onChange={(e) => setComp_id(e.target.value)}
                            className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        >
                            <option value="" disabled>Select Company</option>
                            {stations.map((companys) => (
                                <option key={companys.id} value={companys.id}>{companys.company_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4">
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Title"
                            className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" required />
                    </div>
                    <div className="mb-4">
                    <input
                    type="file"
                    onChange={(e) => setImg(e.target.value)} 
                    placeholder="Image"
                    className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                    />
                    </div>

                    <button onClick={handleEdit} className="m-2 bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded">Save Changes</button>
                    <button onClick={onClose} className="bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded">Close</button>
                </div>
            </div>
        </div>
    );
}

export default EditBrand;
