import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './sidebar';
import { useApiUrl } from '../api/Api';

export default function Events() {
  const apiUrl = useApiUrl();
  const { id } = useParams(); 
  const navigate = useNavigate(); 
  const [eventData, setEventData] = useState(null); 
  const [userData, setUserData] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const [eventResponse, userResponse] = await Promise.all([
          axios.get(`${apiUrl}/events/display/${id}`),
          axios.get(`${apiUrl}/events/getusersinevent/${id}`)
        ]);

        setEventData(eventResponse.data);
        setUserData(userResponse.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (id) {
      fetchEventData();
    }
  }, [id]);


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="bg-white p-4 w-full ml-[20%] flex flex-col">
        <div className="bg-white p-4 w-full rounded-lg mt-14">
        <button 
            onClick={() => navigate(-1)} 
            className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            Back
          </button>
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Event Name: {eventData[0].title}</h1>
          <h1 className="text-2xl font-bold text-gray-800 mb-4">In charge: {eventData[0].incharge}</h1>
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Date: {eventData[0].date}</h1>
          
          
          
          <table className="min-w-full bg-white border-collapse">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Username
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Product
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantity
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {userData.map((contribution, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                    {contribution.username}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                    {contribution.product_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                    {contribution.quantity}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
