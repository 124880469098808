import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from './sidebar';
import './style.css';
import logo1 from './img/station.svg';
import profile from './img/profile.png';
import Chart from 'react-apexcharts'
import { useApiUrl } from '../api/Api';

export default function Analytics() {
  const apiUrl = useApiUrl();
  const [scale, setScale] = useState('scale-100');
  const [prod, setProd] = useState([]);
  const [stations, setStations] = useState([]);
  const [types, setTypes] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [users, setUsers] = useState([]);
  const [accCreated, setAccCreated] = useState([]);
  const [approvedAccounts, setApprovedAccounts] = useState(0);
  const [pendingAccounts, setPendingAccounts] = useState(0);

  const userCount = accCreated.filter(item => item.count > 0).map(item => item.count);
  const categories = accCreated.filter(item => item.count > 0).map(item => item.month);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [prodResponse, stationsResponse, typesResponse, sizesResponse, usersResponse, accountResponse, countApproved, countPending] = await Promise.all([
          axios.get(`${apiUrl}/encode/getprod`),
          axios.get(`${apiUrl}/encode/getstations`),
          axios.get(`${apiUrl}/encode/gettype`),
          axios.get(`${apiUrl}/encode/getsizes`),
          axios.get(`${apiUrl}/users/getuser`),
          axios.get(`${apiUrl}/users/months`),
          axios.get(`${apiUrl}/users/countAcceptedUsers`),
          axios.get(`${apiUrl}/users/countPendingUsers`)

        ]);
        setProd(prodResponse.data);
        setStations(stationsResponse.data);
        setTypes(typesResponse.data);
        setSizes(sizesResponse.data);
        setUsers(usersResponse.data);
        setAccCreated(accountResponse.data);
        setApprovedAccounts(countApproved.data[0]['COUNT(*)']);
        setPendingAccounts(countPending.data[0]['COUNT(*)']);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const detectZoom = () => {
      const zoomLevel = Math.round(window.devicePixelRatio * 100);
      setScale(zoomLevel === 150 || zoomLevel === 175 ? 'scale-75' : 'scale-100');
    };
    detectZoom();
    window.addEventListener('resize', detectZoom);
    return () => {
      window.removeEventListener('resize', detectZoom);
    };
  }, []);

  // Chart Options
  const options = {
    chart: {
      height: '100%',
      maxWidth: '100%',
      type: 'area',
      fontFamily: 'Inter, sans-serif',
      dropShadow: { enabled: false },
      toolbar: { show: false },
    },
    tooltip: { enabled: true, x: { show: false } },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.55,
        opacityTo: 0,
        shade: '#2B9D3E',
        gradientToColors: ['#2B9D3E'],
      },
    },
    dataLabels: { enabled: false },
    stroke: { width: 6 },
    grid: {
      show: false,
      strokeDashArray: 4,
      padding: { left: 2, right: 2, top: 0 },
    },
    series: [{ name: 'New users', data: userCount, color: '#2B9D3E' }],
    xaxis: {
      categories,
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: { show: false },
  };

  // Bar Chart Options
  const [barOptions] = useState({
    chart: { id: 'basic-bar' },
    xaxis: { categories: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023] },
  });

  const [barSeries] = useState([
    { name: 'Waste (kg)', data: [10, 14, 20, 27, 32, 38, 41, 72, 88], color: '#2B9D3E' },
  ]);

  return (
    <>
      <div className="flex h-screen flex-col lg:flex-row w-full relative">
        <Sidebar />
        <div className="bg-gray-300 p-4 w-full ml-[20%] flex flex-col gap-2 lg:p-0">
          {/* Header */}
          <div className="bg-gray-300 text-green-800 w-full flex items-center h-28 text-2xl lg:text-4xl font-bold px-3">
            Hello, Eco Tagger Admin!
          </div>


          <div className="w-full flex items-center justify-around h-1/2">

            <div className="w-[49%] flex flex-col p-3 bg-white h-full rounded-xl">
              <div className="w-full h-[20%] flex items-center justify-between">
                <div className="text-2xl font-medium text-black">Total Users: {approvedAccounts}</div>
                <div className="text-lg font-medium flex text-black">
                  This Week
                  <svg className="w-6 h-6 text-gray-800 inline-flex" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 9-7 7-7-7" />
                  </svg>
                </div>
              </div>
              <div className="w-full h-auto bg-white">
                <Chart options={options} series={options.series} type="area" height={200} />
                <div className="grid grid-cols-1 items-center border-gray-200 border-t justify-between">
                  <div className="flex justify-between items-center pt-5">
                    <button
                      id="dropdownDefaultButton"
                      data-dropdown-toggle="lastDaysdropdown"
                      data-dropdown-placement="bottom"
                      className="text-sm font-medium text-gray-500 hover:text-gray-900 text-center inline-flex items-center"
                      type="button"
                    >
                      Last 7 days
                      <svg className="w-2.5 m-2.5 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                      </svg>
                    </button>
                    <div id="lastDaysdropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                      <ul className="py-2 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
                        <li><a href="#" className="block px-4 py-2 hover:bg-gray-100">Yesterday</a></li>
                        <li><a href="#" className="block px-4 py-2 hover:bg-gray-100">Today</a></li>
                        <li><a href="#" className="block px-4 py-2 hover:bg-gray-100">Last 7 days</a></li>
                        <li><a href="#" className="block px-4 py-2 hover:bg-gray-100">Last 30 days</a></li>
                        <li><a href="#" className="block px-4 py-2 hover:bg-gray-100">Last 90 days</a></li>
                      </ul>
                    </div>
                    <a href="#" className="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-green-600 hover:text-green-700">
                      Users Report
                      <svg className="w-2.5 h-2.5 ms-1.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>


            <div className="w-[49%] flex flex-row p-3 bg-white h-full rounded-xl">
              <div className="w-full md:w-1/2 h-full bg-white flex flex-col justify-between p-4">
                <table className={`w-full min-w-[50px] ${scale}`}>
                  <thead>
                    <tr>
                      <th colSpan="2" className="text-2xl font-medium mb-4 text-black">New Users</th>
                    </tr>
                  </thead>
                  <tbody className='w-96'>
                    {users.slice().reverse().map((user, userIndex) => (
                      <tr key={userIndex}>
                        <td className="py-2 px-4 border-b border-gray-200">
                          <div className="flex items-center">
                            <img src={profile} alt="Profile" className="w-8 h-8 rounded-full mr-2" />
                            <div className="flex flex-col">
                              <a href="#" className="text-gray-600 text-sm font-medium hover:text-green-600 ml-2 truncate">{user.username}</a>
                              <a href="#" className="text-gray-300 text-sm font-thin ml-2 truncate">{user.yearCreated},{user.monthCreated} {user.dayCreated}</a>
                              <span className="text-[13px] font-medium ml-2 text-gray-400">User</span>
                            </div>
                          </div>
                        </td>
                        <td className="py-2 px-1 border-b border-gray-200">
                          <div className="dropdown inline-flex space-x-3">
                            <button type="button" className="dropdown-toggle text-gray-400 hover:text-gray-600 text-sm w-6 h-6 rounded flex items-center justify-center bg-blue-500">
                              <i className="ri-more-2-fill"></i>
                              <svg className="ml-1 w-5 h-5 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="white" strokeLinecap="square" strokeLinejoin="round" strokeWidth="2" d="M7 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h1m4-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7.441 1.559a1.907 1.907 0 0 1 0 2.698l-6.069 6.069L10 19l.674-3.372 6.07-6.07a1.907 1.907 0 0 1 2.697 0Z" />
                              </svg>
                            </button>
                            <button type="button" className="dropdown-toggle text-gray-400 hover:text-gray-600 text-sm w-6 h-6 rounded flex items-center justify-center bg-red-500">
                              <i className="ri-more-2-fill"></i>
                              <svg className="w-5 h-5 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12h4M4 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button type="submit" className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-black rounded-lg focus:ring-4 focus:ring-blue-200 mt-4">
                  View all
                  <svg className="w-6 h-6 text-gray-800 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="#2B9D3E" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 12H5m14 0-4 4m4-4-4-4" />
                  </svg>
                </button>
              </div>


              <div className="w-full md:w-1/2 h-full flex items-center justify-center rounded-xl">
                <div className="text-green-700 text-2xl tems-center justify-center radial-progress" style={{ "--value": "60", "--size": "12rem", "--thickness": "1rem" }} role="progressbar">
                  20%
                </div>
              </div>
            </div>
          </div>


          <div className="w-full flex items-center justify-around h-1/2 relative">
            <div className="w-[42%] bg-white h-full rounded-xl p-4">
              <div className="w-full flex bg-gradient-to-r from-green-300 to-white h-1/2 rounded-xl items-center justify-between relative">
                <div className="w-full max-w-lg mx-auto h-full flex flex-col items-start p-4">
                  <div className="w-full text-2xl font-medium text-black mb-4">Overall User</div>
                  <div className="w-full text-5xl font-bold text-black mb-4">{approvedAccounts}</div>
                  <div className="w-full flex justify-between mb-4">
                    <div className="flex flex-col items-center space-y-2">
                      <div className="text-md font-medium text-black">Approved</div>
                      <div className="text-md font-bold text-black">{approvedAccounts}</div>
                    </div>
                    <div className="flex flex-col items-center space-y-2">
                      <div className="text-md font-medium text-black">Pending</div>
                      <div className="text-md font-bold text-black">{pendingAccounts}</div>
                  </div>
                  </div>
                </div>
                <div className="flex justify-end items-end scale-x-75 scale-y-75">
                <div className="relative w-20 h-5">
                    <progress className="progress progress-success absolute inset-0 w-full h-full transform -rotate-90 translate-x-[30%]" value="20" max="100"></progress>
                  </div>
                  <div className="relative w-20 h-5">
                    <progress className="progress progress-success absolute inset-0 w-full h-full transform -rotate-90 translate-x-[30%]" value="40" max="100"></progress>
                  </div>
                  <div className="relative w-20 h-5">
                    <progress className="progress progress-success absolute inset-0 w-full h-full transform -rotate-90 translate-x-[30%]" value="60" max="100"></progress>
                  </div>
                  <div className="relative w-20 h-5">
                    <progress className="progress progress-success absolute inset-0 w-full h-full transform -rotate-90 translate-x-[30%]" value="80" max="100"></progress>
                  </div>
                  <div className="relative w-20 h-5">
                    <progress className="progress progress-success absolute inset-0 w-full h-full transform -rotate-90 translate-x-[30%]" value="50" max="100"></progress>
                </div>
                </div>
              </div>
              <div className="w-full bg-white-300 h-1/2 rounded-xl overflow-y-auto scrollbar">
                <div className="items-start justify-start text-xl font-medium ml-4 mt-3 text-gray-400">Stations</div>
                <div className="container mx-auto p-4">
                  <div className="grid grid-cols-1 md:grid-cols-3 md:grid-cols-2 gap-4">
                    {stations.map((station, stationIndex) => (
                      <div className="flex items-center" key={stationIndex}>
                        <img src={logo1} alt="Logo 1" className="w-7 h-7 mr-2" />
                        <div>
                          <h2 className="text-md font-bold text-black">{station.station_name}</h2>
                          <p className="text-sm text-gray-400">{station.location}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>


            <div className="w-[56%] h-full bg-gradient-to-r from-white to-green-200 rounded-xl border-5">
              <div className="w-full lg:w flex flex-col p-3 h-full rounded-xl">
                <div className="items-start justify-start text-xl font-medium ml-4 mt-3 text-black">
                  Total Waste Collected as of Today
                </div>
                <Chart options={barOptions} series={barSeries} type="bar" height={300} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
