import React, {useState, useEffect}from 'react'
import Sidebar from './sidebar';
import axios from 'axios';
import { useApiUrl } from '../api/Api';


export default function SubTypes() {
    const apiUrl = useApiUrl();
    const [productRequests, setProductRequests] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [types, setTypes] = useState([]);



    //add

    useEffect(() => {
      const fetchData = async () => {
        try {
          const [types] = await Promise.all([
            axios.get(`${apiUrl}/types/display`)
          ]);
          setTypes(types.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, []); 


    const [formData, setFormData] = useState({
      type_id: '',
      subtype_name: 'asd'
    });
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const newData = new FormData();
      for (const key in formData) {
        if (formData[key] instanceof File) {
          newData.append('files', formData[key]);
        } else {
          newData.append(key, formData[key]);
        }
      }
      try {
        const response = await axios.post(`${apiUrl}/subtypes/register`, formData);
        console.log(response.data);
      } catch (error) {
        console.log(formData);
        console.error('Error submitting data:', error);
      }
    }
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      console.log(formData);
    };
  
    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
  
    };


    useEffect(()=>{
        axios
        .get(`https://back-end-ecotagger-214350645884.asia-southeast1.run.app/subtypes/display`)
        .then((response) => {
            setProductRequests(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data", error);
        });
    })

    //edit



    //delete

    const deleteSubtype = async(id) =>{
      try {
        const response = await axios.put(`https://back-end-ecotagger-214350645884.asia-southeast1.run.app/subtypes/delete/${id}`);
        console.log(response.data);
      } catch (error) {
        console.error('Error submitting data:', error);
      }
    }
  return (
    <>
   <div className="flex h-screen">
        <Sidebar />
        <div className="bg-white p-4 w-full ml-[20%] flex flex-col">
          <div className="bg-white p-4 w-full rounded-lg mt-14">
          <form className="max-w-md mx-auto m-5">
              <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">
                Search
              </label>
              <div className="relative">
                <input
                  type="search"
                  id="default-search"
                  className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                  placeholder="Search Location, Brand..."
                  required
                />
                <button
                  type="submit"
                  className="text-white absolute end-2.5 bottom-2.5 bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
                >
                  Search
                </button>
              </div>
            </form>

            <button 
              type="button" 
              onClick={handleOpenModal} 
              className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-green-600 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-green-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
            >
              Add Subtype
            </button>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
          <th scope="col" className="px-6 py-3">Type</th>
            <th scope="col" className="px-6 py-3">Subtype Name</th>
            <th scope="col" className="px-6 py-3">Status</th>
            <th scope="col" className="px-6 py-3">Action</th>
            
          </tr>
        </thead>
        <tbody>
          {productRequests.map((user, index) => (
            <tr key={index} className={`border-b dark:border-gray-700 ${index % 2 === 0 ? 'bg-green-50 dark:bg-gray-800' : 'bg-white dark:bg-gray-900'}`}>
              <td className="px-6 py-4">{user.type_id}</td>
              <td className="px-6 py-4">{user.subtype_name}</td>
              <td className="px-6 py-4">{user.reqStatus === 0 ? 'Pending' : user.reqStatus === 1 ? 'Approved' : 'Exists'}</td>
              <td className="px-6 py-4">
                <button  href="#" className="font-medium text-green-600 dark:text-blue-500 hover:underline">Edit</button>
                &nbsp;&nbsp;&nbsp;
                <button  href="#" onClick={() => deleteSubtype(user.id)} className="font-medium text-red-600 dark:text-blue-500 hover:underline">Delete</button>
    
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
   </div>
</div>
</div>

{isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-1/4 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-black text-xl font-semibold mb-4">Add New Subtype</h2>
            <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <select
                  name="type_id"
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                >
                  <option value="type_id">Select Type</option>
                  {types.map((types, index) => (
                    <option key={index} value={types.id}>{types.type_name}</option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Subtype Name</label>
                <input
                  type="text"
                  name="subtype_name"
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="flex justify-end">
                <button type="button" onClick={handleCloseModal} className="mr-2 px-4 py-2 bg-red-600 text-white rounded-md">Cancel</button>
                <button type="submit" className="px-4 py-2 bg-green-600 text-white rounded-md">Add Type</button>
              </div>
            </form>
          </div>
        </div>
      )}

    </>
  )
}
