import { useState } from 'react';
import Navbar from './Nav/Nav';
import stock from './img/stock.jpg'
import bg from './img/bg.jpg'
import logo from '../Dashboard/img/logo.svg'

export default function Homepage() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Navbar />

      {/* Hero Section */}
      <div
        className="relative w-full h-screen overflow-hidden bg-cover bg-center"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="relative z-10 flex flex-col justify-center items-start h-full max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-left">
            <span className="text-green-500 text-3xl md:text-5xl font-bold block mb-2">Welcome to</span>
            <h1 className="text-white font-extrabold text-4xl md:text-6xl mb-8">EcoTagger</h1>
            <p className="text-stone-100 text-sm md:text-base max-w-md">
            EcoTagger is a mobile application that allows users to geotag waste collection stations, participate in cleanup events, and contribute to responsible waste management by tracking and 
            reporting waste disposal. EcoTagger enables users to connect with stations, where they can dispose of waste and track the quantity or weight of waste collected.
            </p>
            <button className="mt-8 text-white uppercase bg-green-600 py-3 md:py-4 text-sm md:text-base px-8 md:px-10 border border-white hover:bg-green-700">

              Get The App
          </button>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-[#2c9c3c] py-20">
        <div className="max-w-screen-lg mx-auto flex flex-col md:flex-row justify-between items-center px-4 sm:px-6 lg:px-8">
          <div className="text-center md:text-left max-w-xl mb-10 md:mb-0">
            <h2 className="font-black text-white text-3xl md:text-4xl mb-4">Protect Our Oceans: Reducing Marine Litter for a Sustainable Future</h2>
            <p className="text-black text-base">
            Marine litter, primarily made up of plastics and other waste, threatens marine life, disrupts ecosystems, and pollutes our oceans. By working together to reduce waste and responsibly manage resources, we can help to preserve the health and beauty of our seas.
            </p>
          </div>
          <button className="text-black bg-white uppercase py-3 px-8 md:px-10 border border-sky-950 hover:bg-sky-950 hover:text-green-600 hover:bg-opacity-70">
            Get Started
          </button>
        </div>
      </div>

      {/* Image with Text Section */}
      <div className="py-12 relative bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
          <div className="order-last md:order-first flex flex-col justify-center items-end pr-0 md:pr-16 mb-10 md:mb-0">
            <h2 className="text-[#000000] font-bold text-2xl text-right mb-4 md:mb-12">Geotagging Your Waste Collection</h2>
            <div className="h-full overflow-hidden">
              <img src={stock} className="object-contain w-full h-full" alt="Geotagging" />
            </div>
          </div>
          <div className="bg-green-600 py-16 relative">
            <div className="relative z-20 pl-4 sm:pl-8">
              <h2 className="text-[#ffffff] font-black text-3xl md:text-5xl mb-4 md:mb-10">Join the Movement for a Cleaner Environment with EcoTagger</h2>
              <p className="text-white text-sm md:text-base">
              With EcoTagger, you can easily track waste, join local clean-up events, and support sustainable initiatives, all in one place.
              </p>
              <button className="mt-8 text-white uppercase py-3 px-8 md:px-10 border border-white hover:bg-white hover:bg-opacity-10">
                How to use EcoTagger
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* New Section - Add More Content Here */}
      <div className="py-16 bg-gray-100">
        <div className="max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col justify-center">
            <h2 className="text-3xl font-black text-gray-900 mb-4">Learn More About Our Mission</h2>
            <p className="text-gray-600 text-base mb-6">
              We strive to make the world a cleaner place by helping individuals and communities manage waste in a responsible way.
            </p>
            <button className="bg-green-600 text-white py-3 px-6 uppercase hover:bg-green-700">
              Learn More
            </button>
          </div>
          <div className="overflow-hidden">
            <img src={stock} className="object-cover w-full h-64" alt="EcoTagger Mission" />
          </div>
        </div>
      </div>

      <div class="relative bg-green-600">
    <div class="absolute inset-x-0 bottom-0">
        <svg viewBox="0 0 224 12" fill="currentColor" class="w-full -mb-1 text-white" preserveAspectRatio="none">
            <path
                d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z">
            </path>
        </svg>
    </div>
    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
            <h2 class="mb-6 font-sans text-3xl text-center font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
              Subscribe to our newsletter
            </h2>
            <p class="mb-6 text-base text-white md:text-lg">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
                rem aperiam, eaque ipsa quae. explicabo. Sed ut perspiciatis unde omnis.
            </p>
            <form class="flex flex-col items-center w-full mb-4 md:flex-row md:px-16">
                <input
          placeholder="Email"
          required=""
          type="text"
          class="flex-grow w-full h-12 px-4 mb-3 text-black transition duration-200 border-2 border-transparent rounded appearance-none md:mr-2 md:mb-0 bg-deep-purple-900 focus:border-teal-accent-700 focus:outline-none focus:shadow-outline"
        />
                <a href="/"
                    class="inline-flex items-center justify-center w-full h-12 px-6 font-semibold tracking-wide text-gray-400 bg-white transition duration-200 rounded shadow-md md:w-auto hover:text-deep-purple-900 bg-teal-accent-400 hover:bg-teal-accent-700 focus:shadow-outline focus:outline-none">
                    Subscribe
                </a>
            </form>
            <p class="max-w-md mb-10 text-xs tracking-wide text-white sm:text-sm sm:mx-auto md:mb-16">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.
            </p>
        </div>
    </div>
</div>

<footer class="px-3 pt-4 lg:px-9 border-t-2 bg-gray-50">
    <div class="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">

        <div class="sm:col-span-2">
            <a href="#" class="inline-flex items-center">
                <img src={logo} alt="logo" class="h-8 w-8"/>
                <span class="ml-2 text-xl font-bold tracking-wide text-black">EcoTagger</span>
            </a>
            <div class="mt-6 lg:max-w-xl">
                <p class="text-sm text-black">
                EcoTagger is an app centered around environmental responsibility, specifically targeting the principles of Extended Producer Responsibility (EPR). 
                The purpose of EcoTagger is to make waste collection and sustainable practices more accessible, trackable, and community-driven. By focusing on EPR, 
                it emphasizes accountability and partnership between producers, consumers, and other stakeholders to manage waste responsibly.
                    </p>
            </div>
        </div>

  
        <div>
            <p class="text-base font-bold tracking-wide text-black">COMPANY IS ALSO AVAILABLE ON</p>
            <div class="flex items-center gap-1 px-2">
                <a href="#" class="w-full min-w-xl">
                    <img src="https://mcqmate.com/public/images/icons/playstore.svg" alt="Playstore Button"
                        class="h-10"/>
                </a>
                <a class="w-full min-w-xl" href="https://www.youtube.com/channel/UCo8tEi6SrGFP8XG9O0ljFgA">
                    <img src="https://mcqmate.com/public/images/icons/youtube.svg" alt="Youtube Button"
                        class="h-28"/>
                </a>
            </div>
            <p class="text-base font-bold tracking-wide text-black">Contacts</p>
            <div class="flex">
                <p class="mr-1 text-black">Email:</p>
                <a href="#" title="send email">gabadmin@ecotagger.website</a>
            </div>
        </div>

    </div>

    <div class="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
        <p class="text-sm text-black">© Copyright 2024 Company. All rights reserved.</p>
        <ul class="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
            <li>
                <a href="#"
                    class="text-sm text-black transition-colors duration-300 hover:text-deep-purple-accent-400">Privacy
                    &amp; Cookies Policy
                </a>
            </li>
            <li>
                <a href="#"
                    class="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">Disclaimer
                </a>
            </li>
        </ul>
    </div>

</footer>

    </>
  );
}
