// Import necessary modules
import React, { useState, useEffect } from 'react';
import Sidebar from './sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MapContainer from '../Maps/Maps'; 
import { useApiUrl } from '../api/Api';

export default function Stations() {
  const apiUrl = useApiUrl();
  const navigate = useNavigate();
  const [stations, setStations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newStation, setNewStation] = useState({ station_name: '', location: '' });
  const [markerPosition, setMarkerPosition] = useState(null); 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const stationsResponse = await axios.get('https://back-end-ecotagger-214350645884.asia-southeast1.run.app/stations/display');
        setStations(stationsResponse.data);
        console.log(stationsResponse.data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchData();
  }, []);
  
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewStation({ ...newStation, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!markerPosition) {
      alert("Please select a location on the map");
      return;
    }
    try {
      const stationData = { 
        ...newStation, 
        latitude: markerPosition.lat, 
        longitude: markerPosition.lng ,
        location: markerPosition.location
      };
      
      const response = await axios.post('https://back-end-ecotagger-214350645884.asia-southeast1.run.app/stations/register', stationData);
      console.log(response.data);
      handleCloseModal();
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };
  
  const handleEdit = async (stationId) => {
    const updatedStationData = {
      station_name: newStation.station_name,
      location: newStation.location,
      latitude: markerPosition.lat,
      longitude: markerPosition.lng,
    };

    try {
      await axios.put(`https://back-end-ecotagger-214350645884.asia-southeast1.run.app/stations/update/${stationId}`, updatedStationData);
      alert('Station updated successfully');
      handleCloseModal();
    } catch (error) {
      console.error('Error updating station:', error);
    }
  };
  
  const handleDelete = async (stationId) => {
    try {
      await axios.put(`https://back-end-ecotagger-214350645884.asia-southeast1.run.app/stations/delete/${stationId}`);
      alert('Station deleted successfully');
    } catch (error) {
      console.error('Error deleting station:', error);
    }
  };

  return (
    <>
      <div className="flex h-screen">
        <Sidebar />
        <div className="bg-white p-4 w-full ml-[20%] flex flex-col">
          <div className="bg-white p-4 w-full rounded-lg mt-14">
            <form className="max-w-md mx-auto m-5">
              <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
              <div className="relative">
                <input type="search" id="default-search" className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search Brand, Type..." required />
                <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">Search</button>
              </div>
            </form>
            <button type="button" onClick={handleOpenModal} className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-green-600 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-green-700 focus:z-10 focus:ring-4 focus:ring-gray-100">Add Station</button>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">Station Name</th>
                    <th scope="col" className="px-6 py-3">Location</th>
                    <th scope="col" className="px-6 py-3">Lat</th>
                    <th scope="col" className="px-6 py-3">Long</th>
                    <th scope="col" className="px-6 py-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {stations.slice().reverse().map((station, index) => (
                    <tr key={index} className={`border-b ${index % 2 === 0 ? 'bg-green-50' : 'bg-white'}`}>
                      <td className="px-6 py-4">{station.station_name}</td>
                      <td className="px-6 py-4">{station.location}</td>
                      <td className="px-6 py-4">{station.latitude}</td>
                      <td className="px-6 py-4">{station.longitude}</td>
                      <td className="px-6 py-4">
                      <a href="#" onClick={() => handleEdit(station.id)} className="font-medium text-green-600 hover:underline">Edit</a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#" onClick={() => handleDelete(station.id)} className="font-medium text-red-600 hover:underline">Delete</a>
                        &nbsp;&nbsp;&nbsp;
                      <a href="#" className="font-medium text-blue-600 hover:underline">View</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-1/2 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4 text-black">Add New Station</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Station Name</label>
                <input
                  type="text"
                  name="station_name"
                  value={newStation.station_name}
                  onChange={handleInputChange}
                  className="bg-white mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Location</label>
                <input
                  type="text"
                  name="location"
                  value={newStation.location}
                  onChange={handleInputChange}
                  className="bg-white mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                  disabled
                />
              </div>
              <MapContainer 
                markerPosition={markerPosition} 
                setMarkerPosition={setMarkerPosition} 
                setNewStation={setNewStation}
              />
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="bg-red-600 text-white px-4 py-2 rounded-md mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-600 text-white px-4 py-2 rounded-md"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
