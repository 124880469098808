import { Navigate } from "react-router-dom";
import React, { useEffect } from 'react';
import Login from "../Login/login";

const PrivateRoute = ({ Component, isAuthenticated, setIsAuthenticated }) => {
  useEffect(() => {
    const savedAuthStatus = localStorage.getItem("isAuthenticated");
    if (savedAuthStatus) {
      setIsAuthenticated(JSON.parse(savedAuthStatus));
    }
  }, [setIsAuthenticated]);

  // Update `localStorage` whenever `isAuthenticated` changes
  useEffect(() => {
    localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <Login setIsAuthenticated={setIsAuthenticated} />;
  }

  return <Component />;
};

export default PrivateRoute;
