import React, { useState, useEffect } from 'react';
import Sidebar from './sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useApiUrl } from '../api/Api';
import EditBrand from './Modal/editbrand';

export default function Brands(onClose) {
  const apiUrl = useApiUrl();
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [company, setCompany] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showeditModal, setShoweditModal] = useState(false);
  const [transactionData, setTransactionData] = useState(null);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [companyResponse, brandResponse] = await Promise.all([
          axios.get(`${apiUrl}/company/display`),
          axios.get(`${apiUrl}/brands/display`),
        ]);
        setCompany(companyResponse.data);
        setBrands(brandResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newBrand, setNewBrand] = useState({
    company_id: '',
    brand_name: '',
    img_path: '',
    imgPreview: '',
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setNewBrand({
      company_id: '',
      brand_name: '',
      img_path: '',
      imgPreview: '',
    });
    setIsModalOpen(false);
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBrand({ ...newBrand, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in newBrand) {
      if (newBrand[key] instanceof File) {
        formData.append('files', newBrand[key]);
      } else {
        formData.append(key, newBrand[key]);
      }
    }
    try {
      const response = await axios.post(
        `${apiUrl}/brands/register`,
        formData
      );
      console.log(response.data);
      handleCloseModal();
      navigate(0); // Refresh the page
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewBrand((prevForm) => ({
          ...prevForm,
          [e.target.name]: file,
          [`${e.target.name}Preview`]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setNewBrand((prevForm) => ({
        ...prevForm,
        [e.target.name]: '',
        [`${e.target.name}Preview`]: '',
      }));
    }
  };

  const deleteBrand = async(id) =>{
    try {
      const response = await axios.put(`https://back-end-ecotagger-214350645884.asia-southeast1.run.app/brands/delete/${id}`);
      console.log(response.data);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  }

  const viewBrand = async(id) =>{
    try {
      const response = await axios.get(`${apiUrl}/brands/display/${id}`);
      setTransactionData(response.data[0])
      console.log(response.data);
      setShowModal(true);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  }

    const editBrand = async(id) =>{
      try {
        const response = await axios.get(`${apiUrl}/brands/update/${id}`);
        setTransactionData(response.data[0])
        console.log(response.data);
        setShoweditModal(true);
      } catch (error) {
        console.error('Error submitting data:', error);
      }
    }


  return (
    <>
      <div className="flex h-screen">
        <Sidebar />
        <div className="bg-white p-4 w-full ml-[20%] flex flex-col">
          <div className="bg-white p-4 w-full rounded-lg mt-14">
            <form className="max-w-md mx-auto m-5">
              <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Search Brand, Type..."
                  required
                />
                <button
                  type="submit"
                  className="text-white absolute end-2.5 bottom-2.5 bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
                >
                  Search
                </button>
              </div>
            </form>
            <button
              type="button"
              onClick={handleOpenModal}
              className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-green-600 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-green-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
            >
              Add Brand
            </button>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">Company Name</th>
                    <th scope="col" className="px-6 py-3">Brand Name</th>
                    <th scope="col" className="px-6 py-3">Brand Image</th>
                    <th scope="col" className="px-6 py-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {brands.slice().reverse().map((brand, index) => (
                    <tr key={index} className={`border-b ${index % 2 === 0 ? 'bg-green-50' : 'bg-white'}`}>
                      <td className="px-6 py-4">{brand.company_name}</td>
                      <td className="px-6 py-4">{brand.brand_name}</td>
                      <td className="px-6 py-4">
                        <img
                          className="images"
                          src={
                            brand.img_path
                              ? `${apiUrl}/${brand.img_path.replace(/\\/g, "/")}`
                              : null
                          }
                          alt={brand.brand_name ? brand.img_path : brand.brand_name}
                        />
                      </td>
                      <td className="px-6 py-4">
                        <a href="#" onClick={() => {setSelectedBrand(brand); setIsModalOpen1(true); }} className="font-medium text-green-600 hover:underline">Update</a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#" onClick={() => viewBrand(brand.id)}  className="font-medium text-blue-600 hover:underline">View</a>
                        &nbsp;&nbsp;&nbsp;
                        <a
                          href="#"
                          onClick={() => deleteBrand(brand.id)} // Pass the brand ID here
                          className="font-medium text-red-600 hover:underline"
                        >
                          Delete
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-1/4 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4 text-black">Add New Brand</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Company Name</label>
                <select
                  name="company_id"
                  value={newBrand.company_id}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                >
                  <option value="">Select a Company</option>
                  {company.map((comp) => (
                    <option key={comp.id} value={comp.id}>
                      {comp.company_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Brand Name</label>
                <input
                  type="text"
                  name="brand_name"
                  value={newBrand.brand_name}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Brand Image</label>
                <input
                  type="file"
                  name="img_path"
                  onChange={handleFileChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
                {newBrand.imgPreview && (
                  <div className="mt-2">
                    <img
                      className="w-32 h-32 object-cover rounded"
                      src={newBrand.imgPreview}
                      alt="Brand Preview"
                    />
                  </div>
                )}
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="py-2 px-4 mr-2 bg-gray-500 hover:bg-gray-600 text-white rounded-md"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="py-2 px-4 bg-green-600 hover:bg-green-700 text-white rounded-md"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showModal && transactionData && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h2 className="text-black text-xl font-semibold mb-4">Brand Details</h2>
            <div>
              <p><strong>Brand Name:</strong> {transactionData.brand_name}</p>
              <p><strong>CompanyID:</strong> {transactionData.companyID}</p>
              <p><strong>Company Name:</strong> {transactionData.company_name}</p>
            </div>
            <div className="flex justify-end mt-4">
            <button onClick={handleCloseModal} className="px-4 py-2 bg-red-600 text-white rounded-md">Close</button>

            </div>
          </div>
        </div>
      )}
      {showeditModal && transactionData && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h2 className="text-black text-xl font-semibold mb-4">Brand Edit</h2>
            <div>

            </div>
            <div className="flex justify-end mt-4">
              <button onClick={handleCloseModal} className="px-4 py-2 bg-red-600 text-white rounded-md">Close</button>
            </div>
          </div>
        </div>
      )}
                {isModalOpen1 && (

          <EditBrand
            brand={selectedBrand}
            onClose={() => setIsModalOpen(false)}
          />

        )}
    </>
  );
}
